import React, { useEffect, useState } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import BootstrapTable from 'react-bootstrap-table-next';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalAlterarStatusResgate from '../ModalAlterarStatusResgate';
import ModalCancelarResgate from '../ModalCancelarResgate';
import ModalEntrega from '../ModalEntrega';
import api from '../../../services/API';
import capitalize from '../../../utils/capitalize';
import moment from 'moment-timezone';
import paginationFactory from 'react-bootstrap-table2-paginator';
import sortFunc from '../../../utils/ordernarColunaInt';
import ModalMassRescueAlteration from './ModalMassRescueAlteration';
import ModalObsEdit from './ModalObsEdit';
import ModalPrevisaoEntrega from '../ModalPrevisaoEntrega';

import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const ToSearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      props.onSearch(input.value);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        className="input-theme busca mr-1"
        ref={(n) => (input = n)}
        type="text"
        onKeyUp={handleEnterKey}
      />
      <button
        className="btn-primario-slim"
        onClick={handleClick}
        style={{ width: 40, height: 40 }}
      >
        <i className="fa fa-search px-2"></i>
      </button>
    </div>
  );
};

export default function Resgates() {
  const [
    mostrarModalAlterarStatusResgate,
    setMostrarModalAlterarStatusResgate,
  ] = useState(false);
  const [openEntrega, setOpenEntrega] = useState(false);
  const [mostrarModalCancelarResgate, setMostrarModalCancelarResgate] =
    useState(false);
  const [resgate, setResgate] = useState('');
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [showSelectionIdsModal, setShowSelectionIdsModal] = useState(false);
  const [showObsEditModal, setShowObsEditModal] = useState(false);
  const [obs, setObs] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const usuario = useSelector((state) => {
    return state.usuario;
  });
  const [openPrevisaoEntrega, setOpenPrevisaoEntrega] = useState(false);
  const [resgatePrevisao, setResgatePrevisao] = useState({});

  const formatarData = (celula, valor) => {
    return moment(valor.resgatadoEm).format('DD/MM/YYYY HH:mm:ss');
  };

  const formatarPontos = (celula) => Number(celula).toLocaleString('pt-BR');
  const formatarDinheiro = (celula) => {
    return `R$ ${Number(celula).toFixed(2).toLocaleString('pt-BR')}`;
  };
  const formatarStatus = (celula, valor) => {
    if (valor.status === 0) {
      return (
        <span>
          <i className="fa fa-times"></i> Não
        </span>
      );
    } else if (valor.status === 1) {
      return (
        <span>
          <i className="fa fa-check"></i> Sim
        </span>
      );
    } else if (valor.status === 2) {
      return (
        <span>
          <i className="fa fa-truck"></i> Em trânsito
        </span>
      );
    } else if (valor.status === 3) {
      return (
        <span>
          <i className="fa fa-times"></i> Cancelado
        </span>
      );
    } else if (valor.status === 4) {
      return (
        <span>
          <i className="fa fa-times"></i> Excluído
        </span>
      );
    }
  };

  const formatarPremioResgatado = (celula, valor) => {
    return valor.obs ? `${valor.premio} - ${valor.obs}` : `${valor.premio}`;
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'nome',
      text: 'Pintor',
      sort: true,
      formatter: (celula, valor) => {
        return capitalize(valor.nome);
      },
    },
    {
      dataField: 'check',
      text: '',
      sort: false,
      formatter: (celula, valor) => {
        return valor.check ? (
          <FontAwesomeIcon color="red" icon={faCircle} />
        ) : (
          ''
        );
      },
    },
    {
      dataField: 'entregaNome',
      text: 'Nome Entrega',
      sort: true,
      formatter: (celula, valor) => {
        return capitalize(valor.entregaNome);
      },
    },
    {
      dataField: 'entregaEndereco',
      text: 'Endereço de Entrega',
      sort: true,
    },
    {
      dataField: 'endCidade',
      text: 'Cidade / UF',
      sort: true,
      formatter: (celula, valor) => {
        return `${valor.endCidade} / ${valor.endEstado}`;
      },
    },
    {
      dataField: 'premio',
      text: 'Produto Resgatado',
      formatter: formatarPremioResgatado,
      sort: true,
    },
    {
      dataField: 'tags',
      text: 'Tags',
      sort: true,
    },
    {
      dataField: 'qtde',
      text: 'Quantidade',
      formatter: formatarPontos,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'obs',
      text: 'Observação',
    },
    {
      dataField: 'saldoAnterior',
      text: 'Saldo no Ato',
      formatter: formatarPontos,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'pontosGastos',
      text: 'Pontos Utilizados',
      formatter: formatarPontos,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'credito',
      text: 'Credito',
      formatter: formatarPontos,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalPontos',
      text: 'Total Pontos',
      formatter: formatarPontos,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'valorDoPonto',
      text: 'Valor do Ponto',
      formatter: formatarPontos,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalApagar',
      text: 'Total a pagar',
      formatter: formatarDinheiro,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'status',
      text: 'Entregue',
      formatter: formatarStatus,
      sort: true,
    },
    {
      dataField: 'resgatadoEm',
      text: 'Data do Resgate',
      formatter: formatarData,
      sort: true,
    },
    {
      dataField: 'previsaoEntrega',
      text: 'Previsão de entrega',
      formatter: (celula, valor) => {
        if (valor.status === 3 || valor.status === 4) return '-';
        return valor.previsaoEntrega
          ? moment(valor.previsaoEntrega).format('DD/MM/YYYY')
          : '-';
      },
      sort: true,
    },
    {
      dataField: 'dataEntrega',
      text: 'Data de Entrega',
      formatter: (celula, valor) => {
        if (valor.dataEntrega) {
          return moment(valor.dataEntrega).format('DD/MM/YYYY');
        } else if (valor.status === 1 && valor.previsaoEntrega) {
          return moment(valor.previsaoEntrega).format('DD/MM/YYYY');
        }
        return '-';
      },
      sort: true,
    },
    {
      text: 'Alterar Status',
      dataField: 'alterarStatus',
      isDummyField: true,
      formatter: (celula, valor) =>
        valor.status !== 3 ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalAlterarStatusResgate(valor.id);
            }}
          >
            <i className="fas fa-edit"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            <i className="fas fa-edit"></i>
          </button>
        ),
    },
    {
      text: 'Alterar Previsão de Entrega',
      isDummyField: true,
      dataField: 'previsaoEntrega',
      formatter: (celula, valor) =>
        valor.status !== 3 && valor.status !== 4 ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              onPrevisaoEntrega(valor);
            }}
          >
            <i className="fas fa-edit"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            <i className="fas fa-edit"></i>
          </button>
        ),
      sort: true,
    },
    {
      text: 'Alterar Data de Entrega',
      isDummyField: true,
      dataField: 'dataEntrega',
      formatter: (celula, valor) =>
        valor.status !== 3 && valor.status !== 4 ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              onEntrega(valor);
            }}
          >
            <i className="fas fa-calendar-alt"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            <i className="fas fa-calendar-alt"></i>
          </button>
        ),
      sort: true,
    },
    {
      text: 'Alterar Observação',
      isDummyField: true,
      dataField: 'changeObs',
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            setObs(valor.obs);
            setResgate(valor.id);
            setShowObsEditModal(true);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
      sort: true,
    },
    {
      text: 'Cancelar Resgate',
      isDummyField: true,
      dataField: 'cancelarResgate',
      formatter: (celula, valor) =>
        valor.status !== 3 && valor.status !== 4 ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalCancelarResgate(valor.id);
            }}
          >
            <i className="fas fa-times f-red"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            {valor.status === 3 ? 'CANCELADO' : 'EXCLUÍDO'}
          </button>
        ),
    },
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ];

  useEffect(() => {
    buscarResgates();
  }, []);

  const buscarResgates = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/resgates`);
    if (resultado) {
      setData(resultado.data.resgates);
      setDataOriginal(resultado.data.resgates);
    }
    setBuscando(false);
  };

  const visualizarModalAlterarStatusResgate = (id) => {
    setResgate(id);
    setMostrarModalAlterarStatusResgate(true);
  };

  const onEntrega = (id) => {
    setResgate(id);
    setOpenEntrega(true);
  };

  const fecharModalAlterarStatusResgate = (toUpdate) => {
    setMostrarModalAlterarStatusResgate(false);
    toUpdate &&
      setData(
        data.map((d) => {
          if (d.id === toUpdate.identificador) {
            d.status = +toUpdate.status;
          }
          return d;
        })
      );
  };

  const fecharModalEntrega = (toUpdate) => {
    setOpenEntrega(false);
    toUpdate &&
      setData(
        data.map((d) => {
          if (d.id === toUpdate.identificador) {
            d.dataEntrega = toUpdate.dataEntrega;
          }
          return d;
        })
      );
  };

  const onHideSelectionIdsModal = (toUpdate) => {
    if (toUpdate) {
      setData((prev) =>
        prev.map((rescue) => {
          if (toUpdate.ids.includes(rescue.id)) {
            rescue.status = +toUpdate.status;
          }
          return rescue;
        })
      );
    }
    setShowSelectionIdsModal(false);
  };

  const onHideModalObsEdit = (toUpdate) => {
    if (toUpdate) {
      setData((prev) =>
        prev.map((rescue) => {
          if (toUpdate.id === rescue.id) {
            rescue.obs = toUpdate.obs;
          }
          return rescue;
        })
      );
    }
    setObs('');
    setShowObsEditModal(false);
  };

  const visualizarModalCancelarResgate = (id) => {
    setResgate(id);
    setMostrarModalCancelarResgate(true);
  };

  const fecharModalCancelarResgate = () => {
    setMostrarModalCancelarResgate(false);
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  const ExportCSV = (props) => {
    const downloadExcel = async (data) => {
      const formatarStatusCsv = (valor) => {
        if (valor.status === 0) return 'Não';
        else if (valor.status === 1) return 'Sim';
        else if (valor.status === 2) return 'Em trânsito';
        else if (valor.status === 3) return 'Cancelado';
        else if (valor.status === 4) return 'Excluído';
      };

      const worksheet = XLSX.utils.json_to_sheet(
        data.map((i) => ({
          ID: i.id,
          Status: formatarStatusCsv(i),
          Pintor: capitalize(i.nome),
          'Nome Entrega': capitalize(i.entregaNome),
          'Entrega Endereço': capitalize(i.entregaEndereco),
          'Cidade / UF': `${i.endCidade} / ${i.endEstado}`,
          Premio: formatarPremioResgatado({}, i),
          Quantidade: formatarPontos(i.qtde),
          'Saldo Anterio': Number(formatarPontos(i.saldoAnterior)),
          'Pontos Gastos': Number(formatarPontos(i.pontosGastos)),
          Credito: Number(formatarPontos(i.credito)),
          'Total Pontos': Number(formatarPontos(i.totalPontos)),
          'Valor do Ponto': Number(formatarPontos(i.valorDoPonto)),
          'Total a Pagar': formatarDinheiro(i.totalApagar),
          'Resgatado em': formatarData({}, i),
          'Previsão Entrega': i.previsaoEntrega
            ? moment(i.previsaoEntrega).format('DD/MM/YYYY')
            : '-',
        }))
      );

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Resgates');

      XLSX.writeFile(workbook, 'Resgates.xlsx');
    };

    return (
      <div className="dropdown">
        <button
          id="export-button"
          className="btn-primario-slim"
          style={{ width: 40, height: 40, marginLeft: '4px' }}
          onClick={() => {
            toast.success('Seu download começará em breve, aguarde.', {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 5000,
            });
            downloadExcel(props.data);
          }}
        >
          <i className="fa fa-file-download px-2"></i>
        </button>
      </div>
    );
  };

  const filtrarPorData = () => {
    if (!startDate || !endDate) {
      toast.error('Por favor, selecione ambas as datas para filtrar.');
      return;
    }

    const dataFiltrada = dataOriginal.filter((item) => {
      const dataResgate = moment(item.resgatadoEm).startOf('day');
      const inicio = moment(startDate).startOf('day');
      const fim = moment(endDate).endOf('day');
      return dataResgate.isBetween(inicio, fim, null, '[]');
    });

    setData(dataFiltrada);
  };

  const limparFiltro = () => {
    setStartDate('');
    setEndDate('');
    setData(dataOriginal);
  };

  const onPrevisaoEntrega = (resgate) => {
    setResgatePrevisao(resgate);
    setOpenPrevisaoEntrega(true);
  };

  const fecharModalPrevisaoEntrega = (toUpdate) => {
    setOpenPrevisaoEntrega(false);
    toUpdate &&
      setData(
        data.map((d) => {
          if (d.id === toUpdate.identificador) {
            d.previsaoEntrega = toUpdate.previsaoEntrega;
          }
          return d;
        })
      );
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Resgates</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div className="">
        <button
          onClick={() => setShowSelectionIdsModal(true)}
          className="btn-primario-slim px-4 d-flex g-4 align-items-center "
        >
          <i class="fas fa-stream mr-2"></i>
          <span>Alterar Status em Massa</span>
        </button>

        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse mb-2">
                <ExportCSV data={data} />

                <ToSearch {...props.searchProps} />
              </div>
              <BootstrapTable
                defaultSorted={defaultSorted}
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalCancelarResgate
        show={mostrarModalCancelarResgate}
        onHide={fecharModalCancelarResgate}
        resgate={resgate}
      />
      <ModalAlterarStatusResgate
        show={mostrarModalAlterarStatusResgate}
        onHide={fecharModalAlterarStatusResgate}
        resgate={resgate}
      />

      <ModalEntrega
        open={openEntrega}
        onClose={fecharModalEntrega}
        resgate={resgate}
      />
      <ModalMassRescueAlteration
        open={showSelectionIdsModal}
        onHide={onHideSelectionIdsModal}
        resgate={resgate}
      ></ModalMassRescueAlteration>
      <ModalObsEdit
        open={showObsEditModal}
        onHide={onHideModalObsEdit}
        resgate={resgate}
        obs={obs}
        setObs={setObs}
      ></ModalObsEdit>
      <ModalPrevisaoEntrega
        open={openPrevisaoEntrega}
        onClose={fecharModalPrevisaoEntrega}
        resgate={resgatePrevisao}
      />
    </Container>
  );
}
