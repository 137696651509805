import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';

const formatarData = (celula, valor) => {
  return moment(valor.resgatadoEm).format('DD/MM/YYYY HH:mm:ss');
};

const formatarPrevisao = (celula, valor) => {
  if (valor.status === 3) {
    return '-';
  }

  return valor.previsaoEntrega
    ? moment(valor.previsaoEntrega).format('DD/MM/YYYY ')
    : '-';
};

const formatarQtde = (celula, valor) => {
  return Number(valor.qtde).toLocaleString('pt-BR');
};

const formatarSaldo = (celula, valor) => {
  return Number(valor.saldoAnterior).toLocaleString('pt-BR');
};

const formatarGasto = (celula, valor) => {
  return Number(valor.pontosGastos).toLocaleString('pt-BR');
};

const formatarStatus = (celula, valor) => {
  if (valor.status === 0) {
    return (
      <span>
        <i className="fa fa-times"></i> Não
      </span>
    );
  } else if (valor.status === 1) {
    return (
      <span>
        <i className="fa fa-check"></i> Sim
      </span>
    );
  } else if (valor.status === 2) {
    return (
      <span>
        <i className="fa fa-truck"></i> Em trânsito
      </span>
    );
  } else if (valor.status === 3 || valor.status === 4) {
    return (
      <span>
        <i className="fa fa-times"></i> Cancelado
      </span>
    );
  }
};

const formatarPremioResgatado = (celula, valor) => {
  return valor.obs ? `${valor.premio} - ${valor.obs}` : `${valor.premio}`;
};

const columns = [
  {
    dataField: 'premio',
    text: 'Prêmio Resgatado',
    formatter: formatarPremioResgatado,
  },
  {
    dataField: 'qtde',
    text: 'Quantidade',
    formatter: formatarQtde,
    sortFunc: sortFunc,
    sort: true,
  },
  {
    dataField: 'pontosGastos',
    text: 'Pontos Gastos',
    formatter: formatarGasto,
    sortFunc: sortFunc,
    sort: true,
  },
  {
    dataField: 'saldoAnterior',
    text: 'Saldo Anterior',
    formatter: formatarSaldo,
    sortFunc: sortFunc,
    sort: true,
  },
  {
    dataField: 'status',
    text: 'Entregue',
    formatter: formatarStatus,
  },
  {
    dataField: 'resgatadoEm',
    text: 'Data do Resgate',
    formatter: formatarData,
    sort: true,
  },
  {
    dataField: 'previsaoEntrega',
    text: 'Previsão de entrega',
    formatter: formatarPrevisao,
    sort: true,
  },
  {
    dataField: 'obs',
    text: 'Observação',
    sort: true,
  },
];

const defaultSorted = [
  {
    dataField: 'resgatadoEm',
    order: 'desc',
  },
];

export default function Resgates() {
  useEffect(() => {
    buscarResgates();
  }, []);

  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);

  const buscarResgates = async () => {
    setBuscando(true);
    const resultado = await api.get('/api/resgates');
    if (resultado) {
      setData(resultado.data.resgates);
    }
    setBuscando(false);
  };

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>RESGATES</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div>
        <p className="f-blue">
          Em caso de dúvidas ou consulta sobre o a entrega do seu resgate, fale
          conosco pelo WhatsApp.{' '}
          <a
            className="btn-whats"
            href="https://wa.me/5511997929110?text=Ol%C3%A1%2C+vim+atrav%C3%A9s+do+Clube+Pro+Pintor+e+preciso+de+ajuda%21"
            target="_blank"
          >
            Clique Aqui
          </a>
        </p>
      </div>
      <div>
        <BootstrapTable
          bootstrap4
          noDataIndication={() =>
            buscando ? (
              <span>
                Carregando
                <i className="fas fa-circle-notch fa-spin ml-3"></i>
              </span>
            ) : (
              <span>Sem resultados</span>
            )
          }
          className="tabela-fundo-claro"
          keyField="codigo"
          data={data}
          columns={columns}
          pagination={paginationFactory()}
          defaultSorted={defaultSorted}
          headerClasses="tabela-header-claro"
          bodyClasses="tabela-body-claro"
        />
      </div>
    </Container>
  );
}
